<template>
    <div>
        <!--顶部工具条-->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label="关键字:">
                    <el-input v-model="filters.searchKey" @input="(e) => (filters.searchKey = utils.validForbid(e))" placeholder="请输入项目编号/项目名称" clearable @clear='clearContent'></el-input>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!--列表-->
        <el-table
            :data="dataPer"
            highlight-current-row 
            @current-change="selectCurrentRow"
            v-loading="listLoading"
            @selection-change="selsChange"
            style="width: 100%;"
        >
            <el-table-column type="index" label="序号" width="80" :index='indexMethod'></el-table-column>
            <el-table-column prop="projectNo" label="项目编号"></el-table-column>
            <!-- <el-table-column prop="projectName" label="项目名称"></el-table-column> -->
            <el-table-column prop="projectName" label="项目名称" align="center" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <el-button @click="viewsEntyTemplate(scope.row)" type="text">{{scope.row.projectName}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="contractNo" label="关联合同编号"></el-table-column>
            <el-table-column prop="projectContacts" label="项目联系人"></el-table-column>
            <el-table-column prop="projectPhone" label="联系方式"></el-table-column>
            <el-table-column label="消防设计审查申请表">
                <template slot-scope="scope">
                    <el-button @click.native="showApplyData(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="projectArea" label="图审面积（平方米）"></el-table-column>
            <el-table-column prop="firstAuditTime" label="初审意见签章完成日期"></el-table-column>
            <el-table-column label="项目参与人员">
                <template slot-scope='scope'>
                    <el-button @click="viewsParticipants(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
            <el-table-column label="复核意见">
                <template slot-scope='scope'>
                    <el-button @click="viewReviewReport(scope.row)" type="text">查看</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.pageIndex"
                :page-sizes="pages.pageArr"
                :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.dataCount"
            >
            </el-pagination>
        </el-col>
        <el-dialog
        title="指派图审人员"
        :visible.sync="handleTaskVisible"
        v-model="handleTaskVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item label="标题：">
                    <div>项目名称：{{selectItem.projectName}}</div>
                </el-form-item>
                <el-form-item>
                    <el-radio-group v-model="viewsData.handleType">
                        <el-radio :label="1">继续等待建设单位修改图纸</el-radio>
                        <el-radio :label="2">项目重新设计，冻结归档</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleTaskVisible = false">取消</el-button>
                <el-button @click.native="confirm" :loading="taskVisibleLoading" type="primary">确认</el-button>
            </div>
        </el-dialog>
        <!-- 消防设计审查申请表 -->
        <el-dialog
            title="消防设计审查申请表"
            :visible.sync="projectRegisterDialog"
            v-model="projectRegisterDialog"
            :close-on-click-modal="false"
            width='1200px'
        >
            <WordTable :projectId="selectItem ? selectItem.id : 0" :needData="needData" :dataHideBtn="true"></WordTable>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="projectRegisterDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 项目参与人员 -->
        <el-dialog
            title="项目参与人员"
            :visible.sync="participantsDialog"
            v-model="participantsDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="participantsData"
                border
                style="width: 100%"
                v-loading="participantsLoading"
                ref="multipleTable"
                :highlight-current-row='true'
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="participationTypeName" label="类型" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="姓名" align="center" show-overflow-tooltip>
                    <template slot-scope='scope'>
                        <div>{{scope.row.name}}（{{scope.row.applyStatusName}}）</div>
                    </template>
                </el-table-column>
                <el-table-column prop="phoneNumber" label="手机号" align="center" show-overflow-tooltip></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="participantsDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 复核报告 -->
        <el-dialog
            title="复核报告"
            :visible.sync="reviewReportDialog"
            v-model="reviewReportDialog"
            :close-on-click-modal="false"
            width='50%'
        >
            <el-table
                :data="reviewReportData"
                border
                style="width: 100%"
                v-loading="reviewTableLoading"
                ref="multipleTable"
                :highlight-current-row='true'
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="projectNo" label="生成时间" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="reviewReportClick(scope.row)" type="text" size="small">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="reviewReportDialog = false">取消</el-button>
            </div>
        </el-dialog>
        <!-- 查看 -->
        <el-dialog
            title="查看"
            :visible.sync="viewsApplicationDialog"
            v-model="viewsApplicationDialog"
            :close-on-click-modal="false"
            :width='viewsApplicationWidth'
        >
            <ProjectView @activeChange="activeChange" :needData="needData"></ProjectView>
        </el-dialog>
    </div>
</template>
<script>
import { getDrProjectUnqualifiedList, handleUnqualifiedProject,getProjectParticipants,getProjectDoubleReports } from '../../api/api';
import { getButtonList } from "../../promissionRouter";
import WordTable from "../drProjectManager/wordTable";
import Toolbar from "../../components/Toolbar";
import ProjectView from "../drProjectManager/projectView.vue";
import util from "../../../util/date";
export default {
    components: { Toolbar, WordTable, ProjectView},
    data() {
        return {
            utils: '',
            dataPer:[],
            needData: '',
            listLoading: false,
            //列表选中列
            sels: [],
            //增删改查按钮
            buttonList: [],
            pages: {  //关于分页的obj
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            //顶部筛选条件
            filters: { 
                searchKey: '',
            },
            addLoading: false,
            selectItem: {},
            handleTaskVisible: false,
            taskVisibleLoading: false,
            viewsData: {
                handleType: 1
            },
            // 消防设计审查申请表
            projectRegisterDialog: false,
            // 项目参与人员
            participantsDialog: false,
            participantsLoading: false,
            participantsData: [],
            // 复核报告
            reviewReportDialog: false,
            reviewTableLoading: false,
            reviewReportData: [],
            // 项目查看
            viewsApplicationDialog: false,
            viewsApplicationWidth: '60%',
        };
    },
    methods: {
        // 筛选框清空，重新获取数据
        clearContent(){
            this.getListData()
        },
        //当前行发生变化时
        selectCurrentRow(val) {
            this.selectItem = val;
        },
        //选项发生变化时
        selsChange: function (sels) {
            this.sels = sels;
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getListData();
        },

        handleSizeChange(val){
            this.pages.pageSize = val
            this.getListData();
        },
        //获取数据权限列表
        getListData(){
            this.listLoading = true;
            let params = {
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            };
            if(this.filters.searchKey){
                params.searchKey = this.filters.searchKey;
            }
            getDrProjectUnqualifiedList(params).then((res) => {
                this.dataPer = res.data.response.data;
                this.listLoading = false;
                this.pages.dataCount = res.data.response.dataCount
                this.pages.pageIndex = res.data.response.pageIndex
                if(res.data.response.pageSize > 0){
                    this.pages.pageSize = res.data.response.pageSize
                }
            });
        },
        viewsEntyTemplate(row) {
            this.needData = row
            this.viewsApplicationDialog = true
        },
        handleData() {
            if(!this.selectItem.id){
                this.$message.error("请选择待分配项目！");
                return;
            }
            this.handleTaskVisible = true;
        },
        confirm() {
            let params = {
                projectId: this.selectItem.id,
                handleType: this.viewsData.handleType
            };
            this.taskVisibleLoading = true
            handleUnqualifiedProject(params).then((res) => {
                this.taskVisibleLoading = false;
                if(res.data.response){
                    this.handleTaskVisible = false;
                    this.$message({
                        message: '处理成功！',
                        type: 'success'
                    });
                    this.getListData();
                }else{
                    this.$message({
                        message: '处理失败！',
                        type: 'error'
                    });
                }
            });
        },
        // 查看消防审查申请表
        showApplyData(row){
            this.selectItem = row
            this.projectRegisterDialog = true
        },
        // 项目参与人员
        viewsParticipants(row) {
            this.participantsDialog = true,
            this.participantsLoading = true,
            this.participantsData = []
            var params = {
                projectId: row.id
            }
            getProjectParticipants(params).then(res => {
                var result = res.data
                if(result) {
                    this.participantsData = result.response;
                } else {
                    this.$message.error("加载失败！")
                }
                this.participantsLoading = false
            })
        },
        // 查看复核报告
        viewReviewReport(row) {
            this.reviewReportDialog = true,
            this.reviewTableLoading = true,
            this.reviewReportData = []
            var params = {
                projectId: row.id
            }
            getProjectDoubleReports(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.reviewReportData = result.response
                } else {
                    this.$message.error("加载失败！")
                }
                this.reviewTableLoading = false
            })
        },
        callFunction(item) {
            if(item.func == "getListData") {
                this.pages.pageIndex = 1
            }
            this[item.func].apply(this, item);
        },
        activeChange(val) {
            if(val == '2') {
                this.viewsApplicationWidth = "1200px"
            } else {
                this.viewsApplicationWidth = "60%"
            }
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
        // 复核报告/意见的操作查看功能
        reviewReportClick(row) {
            // 下载复核报告
            let a = document.createElement('a');
            a.href = row.finalReportPath?row.finalReportPath:row.originalReportPath
            document.body.append(a);
            a.click();
            a.remove();
        },
    },
    mounted() {
        this.utils = util
        this.getListData();
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
};
</script>
<style lang="stylus" scoped>
.el-table>>>td, .el-table>>>th{
    padding: 8px!important;
}
.expertSelect{
    width: 500px;
}
</style>
